/* .section4 {
  filter: brightness(0) invert(10);
} */

.services-block-three {
  /* margin: auto; */
}
.services-block-three i {
  font-size: 200px;
}
.services-block-three > a {
  display: block;
  border: 2px solid #d5d5d5;
  border-radius: 4px;
  text-align: center;
  background: #fff;
  padding: 50px 0;
  position: relative;
  margin-bottom: 30px;
}
.services-block-three > a:before {
  display: block;
  content: "";
  width: 9%;
  height: 17%;
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-bottom: 2px solid #991b1b;
  border-right: 2px solid #991b1b;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}
.services-block-three > a:after {
  display: block;
  content: "";
  width: 9%;
  height: 17%;
  position: absolute;
  top: -2px;
  left: -2px;
  border-top: 2px solid #991b1b;
  border-left: 2px solid #991b1b;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}
.padding-15px-bottom {
  padding-bottom: 25px;
}
.services-block-three h4 {
  color: #6f6f6f;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}
.services-block-three p {
  margin-bottom: 0;
  color: #757575;
}
.services-block-three > a:hover {
  opacity: 1;
  border-color: #d5d5d5;
}
a:hover,
a:active {
  color: #03a9f5;
  text-decoration: none;
}

.services-block-three > a:hover:before,
.services-block-three > a:hover:after {
  width: 95%;
  height: 90%;
}

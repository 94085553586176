input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"] {
  -moz-appearance: textfield;
}

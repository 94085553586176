@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.app {
  @apply text-center p-8;
}

.timeline {
  @apply flex flex-col items-start;
}

.timeline-event {
  @apply relative flex items-center mb-8;
}

.timeline-date {
  @apply bg-blue-500 text-white font-bold p-2 mr-4 rounded-full shadow-md;
}

.timeline-content {
  @apply bg-gray-100 p-4 rounded-md shadow-md w-72;
}

.timeline-content h3 {
  @apply text-lg font-semibold mb-2;
}

.timeline-content p {
  @apply text-gray-700;
}

h1 {
  @apply text-4xl font-bold mb-6 text-blue-700;
}

.arrow {
  @apply absolute h-6 w-6 top-1/2 transform -translate-y-1/2 right-0;
}

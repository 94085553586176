.leadershipimg {
  --s: 100px; /* image size */
  --b: 6px; /* border thickness */
  --c: #ae3ec9; /* border color */
  --cb: #e9ecef; /* background color */
  --f: 1; /* initial scale */

  width: 220px;
  margin: auto;
  aspect-ratio: 0.8;
  padding-top: calc(var(--s) / 5);
  cursor: pointer;
  /* border-radius: 0 0 999px 999px; */
  --_g: 50% / calc(100% / var(--f)) 100% no-repeat content-box;
  --_o: calc((1 / var(--f) - 1) * var(--s) / 2 - var(--b));
  outline: var(--b) solid var(--c);
  outline-offset: var(--_o);
  background: radial-gradient(
      circle closest-side,
      var(--cb) calc(99% - var(--b)),
      var(--c) calc(100% - var(--b)) 99%,
      #0000
    )
    var(--_g);
  -webkit-mask: linear-gradient(#000 0 0) no-repeat 50% calc(1px - var(--_o)) /
      calc(100% / var(--f) - 2 * var(--b) - 2px) 50%,
    radial-gradient(circle closest-side, #000 99%, #0000) var(--_g);
  transform: scale(var(--f));
  transition: 0.5s;
}
/* .leadershipimg:hover {
  --f: 1.5; 
} */

.button-pop-in {
  animation: popIn 1s ease-in-out;
}

@keyframes popIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.scroll-animation.active {
  opacity: 10;
  transform: translateY(0);
}

.lg-react-element {
  column-count: 3;
  column-gap: 10px;
  padding: 25px;
}

@media screen and (max-width: 768px) {
  .lg-react-element {
    column-count: 2; /* Adjust the number of columns for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .lg-react-element {
    column-count: 1; /* Adjust the number of columns for even smaller screens */
  }
}

.awards {
  max-width: 100%;
  display: block;
  padding: 5px 0px;
  border-radius: 20px;
  transition: transform 0.2s;
}

@media screen and (max-width: 480px) {
  .awards {
    padding: 4px; /* Adjust padding for smaller screens */
  }
}

.awards img {
  width: 100%; /* Make images responsive */
  height: auto;
}

.awards:hover {
  filter: opacity(0.9);
  transform: scale(1.01);
}
